// import { configureStore } from '@reduxjs/toolkit'
// import { combineReducers } from 'redux'
// import tasks from './Task/index'
// const reducer = combineReducers({
//   // here we will be adding reducers
//   tasks
// })
// const store = configureStore({
//   reducer,
// })
// export default store;




// src/store/store.js
// src/store/store.js
import { configureStore } from '@reduxjs/toolkit';
import tasksReducer from './Task/index'; // Import tasks reducer
import sectionsReducer from './Section/index'; // Import sections reducer

const store = configureStore({
    reducer: {
        tasks: tasksReducer, // Add tasks reducer to the store
        sections: sectionsReducer, // Add sections reducer to the store
    },
});

export default store;

