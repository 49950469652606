// import React from 'react';
// import './Style.css'; // Import CSS for styling

// const Tags = ({ tags = [], disabled = false, onRemoveTag }) => {
//   const handleRemoveTag = (index) => {
//     if (!disabled && onRemoveTag) {
//       onRemoveTag(index);
//     }
//   };

//   return (
//     <div className={`tags ${disabled ? 'tags--disabled' : ''}`}>
//       {tags.length === 0 ? (
//         <span className={`tag ${disabled ? 'tags--disabled' : ''}`}>Add tags</span>
//       ) : (
//         tags.map((tag, index) => (
//           <span key={index} className={`tag ${disabled ? 'tag--disabled' : ''}`}>
//             {tag}
//             {!disabled && (
//               <button className="tag__remove-button" onClick={() => handleRemoveTag(index)}>
//                 ×
//               </button>
//             )}
//           </span>
//         ))
//       )}
//     </div>
//   );
// };

// export default Tags;



import React from 'react';
import './Style.css'; // Import CSS for styling

const Tags = ({ tags = [], disabled = false, onRemoveTag }) => {
  const handleRemoveTag = (index) => {
    if (!disabled && onRemoveTag) {
      onRemoveTag(index); // Only call if not disabled and callback exists
    }
  };

  return (
    <div className={`tags ${disabled ? 'tags--disabled' : ''}`}>
      {tags.length === 0 ? (
        // Display message when no tags are present
        <span className={`tag ${disabled ? 'tag--disabled' : ''}`}>Add tags</span>
      ) : (
        tags.map((tag, index) => (
          <span key={index} className={`tag ${disabled ? 'tag--disabled' : ''}`}>
            {tag}
            {!disabled && (
              // Remove button only shows if not disabled
              <button className="tag__remove-button" onClick={() => handleRemoveTag(index)}>
                &times; {/* Cross icon for removing tag */}
              </button>
            )}
          </span>
        ))
      )}
    </div>
  );
};

export default Tags;
