import React from 'react';
import './Style.css';

const Button = ({ text, onClick, type = 'primary', disabled = false, imageSrc = null, imageAlt = '',Pageref }) => {
  
  return (
    <button
      className={`button button--${type} ${disabled ? 'button--disabled' : ''}`}
      id={Pageref}
      onClick={onClick}
      disabled={disabled}
    >
      {imageSrc ? (
        <img src={imageSrc} alt={imageAlt} className="button__image" />
      ) : (
        text
      )}
    </button>
  );
};

export default Button;
