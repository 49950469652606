import React, { useState } from 'react';
import './Style.css'; // Import CSS for styling

const RangeBar = ({ min = 0, max = 100, step = 1, initialValue = (max + min) / 2 }) => {
    const [value, setValue] = useState(initialValue);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <div className="range-bar">
      <input
        type="range"
        min={min}
        max={max}
        step={step}
        value={value}
        onChange={handleChange}
        className="range-bar__input"
        style={{
          backgroundSize: `${((value - min) / (max - min)) * 100}% 100%`,
        }}
        disabled // Disables user interaction
      />
    </div>
  );
};

export default RangeBar;
