import React, { useState } from 'react';
import './SidebarLayout.css';
import Task from "../../../assets/images/svg/Task.svg";
import User from "../../../assets/images/svg/User_sidebar.svg";
import Timeline from "../../../assets/images/svg/Timeline.svg";
import Logout from "../../../assets/images/svg/Logout.svg";
import UserProfile from "../../../assets/images/User_profile.png"

const Sidebar = () => {
  const [activeLink, setActiveLink] = useState('task');

  return (
    <aside className="sidebar">
      <div className='wraper'>
      <nav>
        <div className="logo">Logo</div>
        <ul className="nav-links">
          <li
            className={activeLink === 'task' ? 'active' : ''}
            onClick={() => setActiveLink('task')}
          >
            <img src={Task} alt='Task' />
            <span>Task</span>
          </li>
          <li
            className={activeLink === 'user' ? 'active' : ''}                       
            onClick={() => setActiveLink('user')}
          >
            <img src={User} alt='User' />
            <span>User</span>
          </li>
          <li
            className={activeLink === 'Timeline' ? 'active' : ''}                       
            onClick={() => setActiveLink('Timeline')}
          >
            <img src={Timeline} alt='Timeline' />
            <span>Timeline</span>
          </li>

        </ul>
      </nav>
      <div className="sidebar-bottom">
        <div className="user-profile">
          <img className='profile-pic ' src={UserProfile} alt='User' title='name' />
        </div>
        <div className="logout">
          <img src={Logout} alt='Logout'/>
        </div>
      </div>
      </div>
    </aside>
  );
};

export default Sidebar;
