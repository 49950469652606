import React from 'react';
import './Style.css';
import CompletedProgressBar from "../../../assets/images/svg/ProgressBar_circular.svg";

const CircularProgressBar = ({ radius = 15, strokeWidth = 3, percentage = 100, disabled = false }) => {
  const normalizedRadius = radius - strokeWidth * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - (percentage / 100) * circumference;

  return (
    <div className={`circular-progress-bar ${disabled ? 'circular-progress-bar--disabled' : ''}`}>
      {percentage === 100 ? (
        <img
          src={CompletedProgressBar} // Replace with the path to your image
          alt="Completed"
          className="circular-progress-bar__image"
          style={{ width: radius, height: radius, opacity: disabled ? 0.5 : 1 }}
        />
      ) : (
        <svg height={radius * 2} width={radius * 2} style={{ opacity: disabled ? 0.5 : 1 }}>
          <circle
            className="circular-progress-bar__circle circular-progress-bar__circle--background"
            stroke="grey"
            strokeWidth={strokeWidth}
            r={normalizedRadius}
            cx={radius}
            cy={radius}
          />
          <circle
            className="circular-progress-bar__circle circular-progress-bar__circle--progress"
            stroke="blue"
            strokeWidth={strokeWidth}
            r={normalizedRadius}
            cx={radius}
            cy={radius}
            strokeDasharray={circumference}
            strokeDashoffset={strokeDashoffset}
          />
        </svg>
      )}
      <div className="circular-progress-bar__text">
        {/* {`${percentage}%`} */}
      </div>
    </div>
  );
};

export default CircularProgressBar;
