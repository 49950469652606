import { useState } from "react";
import { DayPicker } from "react-day-picker";
import "react-day-picker/style.css";
import CalenderIcon from "../../../assets/images/svg/Calender.svg"; // Update the path to your calendar icon
import './Style.css'; // Import CSS for custom styling

function MyDatePicker({ disabled = false }) {
  const [selected, setSelected] = useState(null);
  const [isOpen, setIsOpen] = useState(false); // State to manage calendar visibility
  const [currentMonth, setCurrentMonth] = useState(new Date()); // State to manage the currently displayed month

  const handleImageClick = () => {
    if (!disabled) {
      setIsOpen(!isOpen); // Toggle calendar visibility only if not disabled
    }
  };

  const handleDateSelect = (date) => {
    if (!disabled) {
      setSelected(date);
      setCurrentMonth(date); // Update the month to the selected date
      setIsOpen(false); // Close calendar on date selection
    }
  };

  // Format the selected date for display
  const formatDate = (date) => {
    if (!date) return '';

    const today = new Date();
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');

    // Check if the date is today, tomorrow, or yesterday
    if (date.toDateString() === today.toDateString()) {
      return 'Today';
    } else if (
      date.toDateString() === new Date(today.setDate(today.getDate() + 1)).toDateString()
    ) {
      return 'Tomorrow';
    } else if (
      date.toDateString() === new Date(today.setDate(today.getDate() - 2)).toDateString()
    ) {
      return 'Yesterday';
    }

    return `${day}/${month}`; // Format as "DD/MM"
  };

  return (
    <div className={`date-picker ${disabled ? 'date-picker--disabled' : ''}`} style={{ display: "inline-block" }}>
      <div onClick={handleImageClick} style={{ cursor: disabled ? "not-allowed" : "pointer", display: "inline-block" }}>
        {selected ? (
          <span>{formatDate(selected)}</span>
        ) : (
          <img
            src={CalenderIcon}
            alt="Open Calendar"
            style={{ cursor: disabled ? "not-allowed" : "pointer", opacity: disabled ? 0.5 : 1 }}
          />
        )}
      </div>
      {isOpen && !disabled && (
        <div className="calender" style={{ position: "absolute", zIndex: 1000, border: "1px solid #E8E9ED" }}>
          <DayPicker
            captionLayout="dropdown"
            startMonth={new Date(2015, 6)}
            endMonth={new Date(2025, 9)}
            mode="single"
            selected={selected}
            onSelect={handleDateSelect}
            showOutsideDays
            month={currentMonth} // Pass the current month to DayPicker
          />
        </div>
      )}
    </div>
  );
}

export default MyDatePicker;
