import React from 'react';
// import Assignee from '../../../assets/images/svg/Assignee.svg'
import user3 from '../../../assets/images/user3.png'
// import Organisation from '../../../assets/images/svg/Organisation.svg'
import './Style.css';

const ImageGallery = ({ images, shape = 'square', disabled = false }) => {
  const visibleImages = images.slice();

  const imageStyle = {
    width: '26px',
    height: '26px',
    objectFit: 'cover',
    borderRadius: shape === 'circle' ? '50%' : '0',
    marginRight: '10px',
    opacity: disabled ? 0.5 : 1,
    pointerEvents: disabled ? 'none' : 'auto',
  };

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', opacity: disabled ? 0.5 : 1 }}>
      {visibleImages.flat().map((image, index) => (
        <div
          key={index}
          className={`containerStyle ${disabled ? 'containerStyle--disabled' : ''}`}
        >
          <img
            src={user3} // Updated to use image directly
            alt={`Image ${index + 1}`}
            style={imageStyle}
            title={`Image ${index + 1}`} // Adjusted to use the index for the title
          />
          {shape === 'square' && <span>Image {index + 1}</span>} {/* Adjusted to display index as text */}
        </div>
      ))}

    </div>
  );
};

export default ImageGallery;
