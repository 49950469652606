import InputBox from "../../Atom/Input/InputField";
import SearchIcon from '../../../assets/images/svg/Search_icon.svg'
import './Style.css'
const SearchInput = ({ value, onChange, placeholder, disabled = false }) => {
    return (
        <div className="SearchInput"> 
            <img src={SearchIcon} alt="Search_icon" />
            <InputBox placeholder={"Search Task"}/>
        </div>
    )
}
export default SearchInput