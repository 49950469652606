// import React from 'react';
// import './Style.css'
// const InputBox = ({ value, onChange, placeholder, disabled=false,onKeyPress}) => {
//   return (
//     <input 
//       className='input'
//       type="text" 
//       value={value} 
//       onChange={onChange} 
//       placeholder={placeholder} 
//       disabled={disabled} 
//       onKeyPress={onKeyPress}
//     />
//   );
// };

// export default InputBox;

import React from 'react';
import './Style.css';

const InputBox = ({ value, onChange, placeholder, disabled = false, onKeyPress,onKeyDown }) => {
  return (
    <input
      className='input'
      type="text"
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      disabled={disabled}
      onKeyDown={onKeyDown}// Make sure onKeyDown is passed
      onKeyPress={onKeyPress}// Make sure onKeyDown is passed
    />
  );
};

export default InputBox;
