import { createSlice } from '@reduxjs/toolkit';

const taskSlice = createSlice({
    name: 'tasks',
    initialState: {
        tasks: [],
    },
    reducers: {
        setTasks: (state, action) => {
            state.tasks = action.payload;
        },
        addTask: (state, action) => {
            state.tasks = [action.payload, ...state.tasks]; // Add new tasks at the top
        },
        updateTask: (state, action) => {
            const { id, field, value } = action.payload;
            state.tasks = state.tasks.map(task =>
                task.id === id ? { ...task, [field]: value } : task
            );
        },
    },
});

export const { setTasks, addTask, updateTask } = taskSlice.actions;
export default taskSlice.reducer;
