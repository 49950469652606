import React, { useState, useEffect } from "react";
import { Tabs, TabHeader, TabContent } from "../Atom/Tabs/Tabs";
import Section from '../../assets/images/svg/Section.svg';
import List from '../../assets/images/svg/List.svg';
import Arrow from '../../assets/images/svg/Left_arrow.svg';
import RecentTask from '../../assets/images/svg/RecentTask.svg';
import Upcoming from '../../assets/images/svg/Upcoming.svg';
import RecentComments from '../../assets/images/svg/RecentComments.svg';
import MainLayout from "../context/Mainlayout";
import Card from "../Atom/Card/Card";
import RightArrow from '../../assets/images/svg/Right_arrow.svg';
import './Tasks.css';  // Import the CSS file
import Button from "../Atom/Button/Button";
import SearchInput from "../Molecules/SearchInput/SearchInput";
import SectionView from "../Templates/SectionView/SectionView";
import data from '../../data.json'; // Import the JSON file
import TaskDetails from "../organisms/taskDetails";

const Tasks = () => {
    const [sections, setSections] = useState([]);
    const [tasks, setTasks] = useState([]);
    const [filteredTasks, setFilteredTasks] = useState([]);
    const [activeTab, setActiveTab] = useState("All");
    const [open, setOpen] = useState(false);

    useEffect(() => {
        const { sections: dataSections, tasks: dataTask } = data;

        setSections(dataSections);
        setTasks(dataTask);
    }, []);

    const handleTabChange = (tabName) => {
        setActiveTab(tabName);
    };

    // Filter tasks based on the active tab
    useEffect(() => {
        const tmpTasks = (tasks || [])?.filter(task => {
            if (activeTab === "All") {
                return true; // Show all tasks when "All" is active
            }
            return task.sectionId === sections.find(section => section.value === activeTab)?.id;
        });
        setFilteredTasks(tmpTasks);
    }, [tasks, sections, activeTab]);

    function toggleSidebar() {
        setOpen(!open);
    }

    function leftContent() {
        return (
            <div style={{ padding: "10px" }}>
                <div className="topSection">
                    <div className="bottomLevel">
                        <Card title="Recent Task" body={<img src={RecentTask} alt="Recent Task" />} />
                        <Card title="Upcoming" body={<img src={Upcoming} alt="Upcoming" />} />
                        <Card title="Recent Comments" body={<img src={RecentComments} alt="Recent Comments" />} />
                    </div>
                </div>
                <div className="bottomSection">
                    <Tabs tabs={[{ label: "All", id: 0, onClick: () => handleTabChange("All") }, ...sections?.map(x => ({ label: x.value, id: x.id, onClick: () => handleTabChange(x.value) }))]} styleObject={{ fontSize: "18px", borderBottom: "2px solid rgba(55, 135, 235, 1)", imageWidth: "11px" }} activeColor="rgba(55, 135, 235, 1)">
                        <TabHeader onClick={() => handleTabChange("All")}>All</TabHeader>
                        {sections.map((section, index) => (
                            <TabHeader key={index} onClick={() => handleTabChange(section.value)}>
                                {section.value}
                            </TabHeader>
                        ))}
                        <tabHeaderRightSide>
                            <div style={{ display: "flex", gap: "10px", alignItems: "center", marginBottom: '10px' }}>
                                <SearchInput placeholder={""} />
                                <div className="Divider"></div>
                                <Button text="Add Section" type="primary" />
                                {!open && ( // Conditionally render the button based on 'open' state
                                    <Button Pageref='openSidebar' onClick={toggleSidebar} imageSrc={RightArrow} imageAlt="Button Image" type="trasperant" />
                                )}
                            </div>
                        </tabHeaderRightSide>
                        <TabContent>
                            {/* Show SectionView only for the active tab's data */}
                            <SectionView
                                sections={sections.filter(section => activeTab === "All" || section.value === activeTab)}
                                tasks={filteredTasks}
                                setSections={setSections}
                                setTasks={setTasks}
                            />
                        </TabContent>
                    </Tabs>
                </div>
            </div>
        );
    }

    // function rightContent() {
    //     return (
    //         <>
    //         <div style={{ padding: "10px", zIndex: "1111", display: "flex", gap: "10px", borderBottom: "1px solid rgba(237, 238, 244, 1)" }} activeColor="rgba(50, 50, 50, 1)">
    //             <div>
    //                 <img src={Arrow} alt="Arrow" onClick={() => setOpen(false)} />
    //             </div>
    //             <div>
    //                 Task Detail
    //             </div>
    //         </div>
    //         <div>
    //             dfsdfsdf
    //         </div>
    //         </>
    //     );
    // }

    return (
        <div style={{ margin: "20px 20px 20px 0px", height: "90%" }}>
            <Tabs styleObject={{ fontSize: "14px", borderBottom: "2px solid rgba(50, 50, 50, 1)", imageWidth: "11px" }}>
                <TabHeader image={Section}>Section</TabHeader>
                <TabHeader image={List}>List</TabHeader>
                <TabContent>
                <MainLayout sidebar={leftContent()} leftContentSize={"100%"} rightContentSize={open ? "449px" : "0px"}>
                        {open && (
                            <div style={{ display: "block", height: "100%", background: "#fff", borderLeft: "2px solid rgba(237, 238, 244, 1)", borderRight: "2px solid rgba(237, 238, 244, 1)" }}>
                                <TaskDetails onClose={() => setOpen(false)} /> {/* Use the new TaskDetails component */}
                            </div>
                        )}
                    </MainLayout>
                </TabContent>
                <TabContent>Content 2 for Tab 2</TabContent>
            </Tabs>
        </div>
    );
};

export default Tasks;
