// import React from "react";
// import DraggableSection from "./DraggableSection"; // Adjust path as needed
// import { useSelector } from "react-redux";

// const SectionView = ({ sections, tasks = [], setSections, setTasks }) => {
//     const taskss = useSelector((state) => state.tasks.tasks);
//     console.log("11111111111111111111111",taskss);
   
//     // Handle section input changes
//     const handleInputChange = (sectionId, event) => {
     
//         const newSections = sections.map((section) =>
//             section.id === sectionId ? { ...section, value: event.target.value } : section
//         );
//         setSections(newSections);
//     };

//     // Handle task input changes
//     const handleTaskChange = (sectionId, event) => {
      
//         const newSections = sections.map((section) =>
//             section.id === sectionId ? { ...section, taskValue: event.target.value } : section
//         );
//         setSections(newSections);
//     };

//     // Handle changes in task rows
//     const handleTaskRowChange = (taskId, field, value) => {
       
//         const newTasks = tasks.map((task) =>
//             task.id === taskId ? { ...task, [field]: value } : task
//         );
//         setTasks(newTasks);
//     };

//     // Add a new task row
//     const addRow = (sectionId, value) => {
//         const nonSpaceCharCount = value.replace(/\s/g, "").length;
//         let value1 = value;
//         // Validation for at least 3 non-space characters
//         if (nonSpaceCharCount < 3) {
//             alert("Input must contain at least 3 non-space characters.");
//             return; // Exit if validation fails
//         }
//         const newTaskId = `task${tasks.length + 1}`;
//         const newTasks = [
//             ...tasks,
//         ];
//         newTasks.unshift({
//             id: newTaskId,
//             sectionId: sectionId,
//             value: value1,
//             status: 0, // Default status
//             dueDate: "",
//             organisation: "",
//             assignee: "",
//             comments: false,
//             tags: [],
//         })
     
//         setTasks(newTasks);

//         // Clear task input after adding
//         const newSections = sections.map((section) =>
//             section.id === sectionId ? { ...section, taskValue: "" } : section
//         );
//         setSections(newSections);
//     };

//     // Reorder sections
//     const moveSection = (fromSectionId, toSectionId) => {
//         const fromIndex = sections.findIndex((section) => section.id === fromSectionId);
//         const toIndex = sections.findIndex((section) => section.id === toSectionId);

//         if (fromIndex >= 0 && toIndex >= 0) {
//             const newSections = [...sections];
//             const [movedSection] = newSections.splice(fromIndex, 1);
//             newSections.splice(toIndex, 0, movedSection);
//             setSections(newSections);
//         }
//     };

//     // Get tasks for a specific section
//     const getTasksForSection = (sectionId) => {
//         return tasks.filter((task) => task.sectionId === sectionId); 
//     };

//     return (
//         <div>
//             {sections.map((section) => (
//                 <DraggableSection
//                     key={section.id}    
//                     id={section.id}
//                     index={section.id}
//                     moveSection={moveSection}
//                     inputValue={section.value}
//                     handleInputChange={(event) => handleInputChange(section.id, event)}
//                     taskValue={section.taskValue || ""}
//                     handleTaskChange={(event) => handleTaskChange(section.id, event)}
//                     rows={getTasksForSection(section.id)}
//                     addRow={(taskValue) => addRow(section.id, taskValue)}
//                     handleTaskRowChange={(taskId, field, value) =>
//                         handleTaskRowChange(taskId, field, value)
//                     }
//                 />
//             ))}
//         </div>
//     );
// };

// export default SectionView;


import React from "react";
import { useSelector, useDispatch } from "react-redux";
import DraggableSection from "./DraggableSection"; // Adjust path as needed
import { addTask,updateTask } from "../../../store/Task/index"; // Adjust path as needed

const SectionView = ({ sections, setSections }) => {
    const dispatch = useDispatch();
    const tasks = useSelector((state) => state.tasks.tasks);

    // Handle section input changes
    const handleInputChange = (sectionId, event) => {
        const newSections = sections.map((section) =>
            section.id === sectionId ? { ...section, value: event.target.value } : section
        );
        setSections(newSections);
    };

    // Handle task input changes
    const handleTaskChange = (sectionId, event) => {
        const newSections = sections.map((section) =>
            section.id === sectionId ? { ...section, taskValue: event.target.value } : section
        );
        setSections(newSections);
    };

    // Handle changes in task rows
    const handleTaskRowChange = (taskId, field, value) => {
        // Dispatch an action to update the task in the store
        dispatch(updateTask({ taskId, field, value }));
    };

    // Add a new task row
    const addRow = (sectionId, value) => {
        const nonSpaceCharCount = value.replace(/\s/g, "").length;
        let value1 = value;
        // Validation for at least 3 non-space characters
        if (nonSpaceCharCount < 3) {
            alert("Input must contain at least 3 non-space characters.");
            return; // Exit if validation fails
        }
        const newTaskId = `task${tasks.length + 1}`;
        const newTask = {
            id: newTaskId,
            sectionId: sectionId,
            value: value1,
            status: 0, // Default status
            dueDate: "",
            organisation: "",
            assignee: "",
            comments: false,
            tags: [],
        };
        
        // Dispatch an action to add the new task to the store
        dispatch(addTask(newTask));

        // Clear task input after adding
        const newSections = sections.map((section) =>
            section.id === sectionId ? { ...section, taskValue: "" } : section
        );
        setSections(newSections);
    };

    // Reorder sections
    const moveSection = (fromSectionId, toSectionId) => {
        const fromIndex = sections.findIndex((section) => section.id === fromSectionId);
        const toIndex = sections.findIndex((section) => section.id === toSectionId);

        if (fromIndex >= 0 && toIndex >= 0) {
            const newSections = [...sections];
            const [movedSection] = newSections.splice(fromIndex, 1);
            newSections.splice(toIndex, 0, movedSection);
            setSections(newSections);
        }
    };

    // Get tasks for a specific section
    const getTasksForSection = (sectionId) => {
        return tasks.filter((task) => task.sectionId === sectionId);
    };

    return (
        <div>
            {sections.map((section) => (
                <DraggableSection
                    key={section.id}    
                    id={section.id}
                    index={section.id}
                    moveSection={moveSection}
                    inputValue={section.value}
                    handleInputChange={(event) => handleInputChange(section.id, event)}
                    taskValue={section.taskValue || ""}
                    handleTaskChange={(event) => handleTaskChange(section.id, event)}
                    rows={getTasksForSection(section.id)}
                    addRow={(taskValue) => addRow(section.id, taskValue)}
                    handleTaskRowChange={(taskId, field, value) =>
                        handleTaskRowChange(taskId, field, value)
                    }
                />
            ))}
        </div>
    );
};

export default SectionView;
