import { createSlice } from '@reduxjs/toolkit';

const sectionSlice = createSlice({
    name: 'sections',
    initialState: {
        sections: [],
    },
    reducers: {
        setSections: (state, action) => {
            state.sections = action.payload;
        },
        addSection: (state, action) => {
            state.sections = [action.payload, ...state.sections]; // Add new sections at the top
        },
        updateSection: (state, action) => {
            const { id, field, value } = action.payload;
            state.sections = state.sections.map(section =>
                section.id === id ? { ...section, [field]: value } : section
            );
        },
    },
});

export const { setSections, addSection, updateSection } = sectionSlice.actions;
export default sectionSlice.reducer;
