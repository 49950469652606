import React from 'react';
import './Style.css';

const Card = ({ title, body, variant = 'default' }) => {
  return (
    <div className={`card card-${variant}`}>
      <div className="card-title">
        {title}
      </div>
      <div className="card-body">
        {body}
      </div>
    </div>
  );
};

export default Card;
