// import React, { useState } from "react";
// import InputBox from "../../Atom/Input/InputField";
// import CircularProgressBar from "../../Atom/CircularProgressbar/circularProgressBar";
// import MyDatePicker from "../../Atom/Date/Date";
// import ImageGallery from "../../Atom/Image/Image";
// import Tags from "../../Atom/Tags/tags";
// import OrganisationIcon from "../../../assets/images/svg/Organisation.svg";
// import AssigneeIcon from "../../../assets/images/svg/Assignee.svg";
// import CommentsIcon from "../../../assets/images/svg/Comments.svg";

// const TaskRow = ({
//     row,
//     handleTaskChange,
//     handleStatusChange,
//     handleDueDateChange,
//     handleOrganisationChange,
//     handleAssigneeChange,
//     handleCommentsChange,
//     handleTagsChange,
// }) => {
//     // Local state to manage whether the input is active
//     const [isEditing, setIsEditing] = useState(false);

//     // Handler for double-click to enable editing
//     const handleDoubleClick = () => {
//         setIsEditing(true);
//     };

//     // Handler to disable editing when input loses focus or on Enter
//     const handleBlurOrEnter = (e) => {
//         if (e.type === "blur" || (e.type === "keydown" && e.key === "Enter")) {
//             setIsEditing(false);
//         }
//     };

//     return (
//         <tr>
//             <td></td>
//             <td>
//                 {/* Conditionally render input or text based on isEditing state */}
//                 {isEditing ? (
//                     <InputBox
//                         value={row.value}
//                         onChange={(e) => handleTaskChange(e, row.id)}
//                         onBlur={handleBlurOrEnter}  // Disable editing on blur
//                         onKeyDown={handleBlurOrEnter}  // Disable editing on Enter
//                         autoFocus  // Automatically focus the input when editing starts
//                     />
//                 ) : (
//                     <span onDoubleClick={handleDoubleClick}>  {/* Enable editing on double-click */}
//                         {row.value || "Double-click to edit"}  {/* Placeholder text if no value */}
//                     </span>
//                 )}
//             </td>
//             <td>
//                 <CircularProgressBar
//                     percentage={row.status}
//                     onChange={(e) => handleStatusChange(e, row.id)}
//                 />
//             </td>
//             <td>
//                 <MyDatePicker
//                     selectedDate={row.dueDate}
//                     onChange={(date) => handleDueDateChange(date, row.id)}
//                 />
//             </td>
//             <td>
//                 {row.organisation ? (
//                     <ImageGallery images={[row.organisation]} />
//                 ) : (
//                     <img src={OrganisationIcon} alt="Organisation" />
//                 )}
//             </td>
//             <td>
//                 {row.assignee ? (
//                     <ImageGallery images={[row.assignee]} shape="circle" />
//                 ) : (
//                     <img src={AssigneeIcon} alt="Assignee" />
//                 )}
//             </td>
//             <td>
//                 <img src={CommentsIcon} alt="Comments" />
//             </td>
//             <td>
//                 <Tags
//                     tags={row.tags || []}
//                     onRemoveTag={(tagIndex) => handleTagsChange(tagIndex, row.id)}
//                 />
//             </td>
//         </tr>
//     );
// };

// export default TaskRow;





// import React, { useState } from "react";
// import InputBox from "../../Atom/Input/InputField";
// import CircularProgressBar from "../../Atom/CircularProgressbar/circularProgressBar";
// import MyDatePicker from "../../Atom/Date/Date";
// import ImageGallery from "../../Atom/Image/Image";
// import Tags from "../../Atom/Tags/tags";
// import OrganisationIcon from "../../../assets/images/svg/Organisation.svg";
// import AssigneeIcon from "../../../assets/images/svg/Assignee.svg";
// import CommentsIcon from "../../../assets/images/svg/Comments.svg";

// const TaskRow = ({
//     row,
//     handleTaskChange,
//     handleStatusChange,
//     handleDueDateChange,
//     handleOrganisationChange,
//     handleAssigneeChange,
//     handleCommentsChange,
//     handleTagsChange,
// }) => {
//     const [isEditing, setIsEditing] = useState(false);
//     const [inputValue, setInputValue] = useState(row.value || "");

//     // Enable editing on double-click
//     const handleDoubleClick = () => {
//         setIsEditing(true);
//         setInputValue(row.value); // Reset the input value when entering edit mode
//     };

//     // Handle input change
//     const handleInputChange = (e) => {
//         setInputValue(e.target.value);
//     };

//     // Save value and exit edit mode on Enter or blur
//     const handleBlurOrEnter = (e) => {
//         if (e.type === "blur" || (e.type === "keydown" && e.key === "Enter")) {
//             if (inputValue.length >= 3) {
//                 console.log('Saving value:', inputValue); // Debugging
//                 handleTaskChange(row.id, inputValue); // Update the value using handleTaskChange
//                 setIsEditing(false); // Exit edit mode
//             } else {
//                 alert("Input must be at least 3 characters long.");
//             }
//         }
//     };

//     return (
//         <tr>
//             <td></td>
//             <td>
//                 {isEditing ? (
//                     <InputBox
//                         value={inputValue}
//                         onChange={handleInputChange}
//                         onBlur={handleBlurOrEnter}
//                         onKeyDown={handleBlurOrEnter} // Use only onKeyDown for handling Enter
//                         autoFocus
//                     />
//                 ) : (
//                     <span onDoubleClick={handleDoubleClick}>
//                         {row.value || "Double-click to edit"}
//                     </span>
//                 )}
//             </td>
//             <td>
//                 <CircularProgressBar
//                     percentage={row.status}
//                     onChange={(e) => handleStatusChange(e, row.id)}
//                 />
//             </td>
//             <td>
//                 <MyDatePicker
//                     selectedDate={row.dueDate}
//                     onChange={(date) => handleDueDateChange(date, row.id)}
//                 />
//             </td>
//             <td>
//                 {row.organisation ? (
//                     <ImageGallery images={[row.organisation]} />
//                 ) : (
//                     <img src={OrganisationIcon} alt="Organisation" />
//                 )}
//             </td>
//             <td>
//                 {row.assignee ? (
//                     <ImageGallery images={[row.assignee]} shape="circle" />
//                 ) : (
//                     <img src={AssigneeIcon} alt="Assignee" />
//                 )}
//             </td>
//             <td>
//                 <img src={CommentsIcon} alt="Comments" />
//             </td>
//             <td>
//                 <Tags
//                     tags={row.tags || []}
//                     onRemoveTag={(tagIndex) => handleTagsChange(tagIndex, row.id)}
//                 />
//             </td>
//         </tr>
//     );
// };

// export default TaskRow;



import React, { useState } from "react";
import InputBox from "../../Atom/Input/InputField";
import CircularProgressBar from "../../Atom/CircularProgressbar/circularProgressBar";
import MyDatePicker from "../../Atom/Date/Date";
import ImageGallery from "../../Atom/Image/Image";
import Tags from "../../Atom/Tags/tags";
import OrganisationIcon from "../../../assets/images/svg/Organisation.svg";
import AssigneeIcon from "../../../assets/images/svg/Assignee.svg";
import CommentsIcon from "../../../assets/images/svg/Comments.svg";
import { useDispatch } from "react-redux";
import { updateTask } from "../../../store/Task/index"; // Import the updateTask action

const TaskRow = ({ 
    row,
    handleStatusChange,
    handleDueDateChange,
    handleOrganisationChange,
    handleAssigneeChange,
    handleCommentsChange,
    handleTagsChange,   
}) => {
    const dispatch = useDispatch();
    const [isEditing, setIsEditing] = useState(false);
    const [inputValue, setInputValue] = useState(row.value || "");
    const [isSidebarOpen, setIsSidebarOpen] = useState(false); // State to manage sidebar

    // Enable editing on double-click
    const handleDoubleClick = () => {
        setIsEditing(true);
        setInputValue(row.value); // Reset the input value when entering edit mode
    }; 

    // Handle input change
    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    // Save value and exit edit mode on Enter or blur
    const handleBlurOrEnter = (e) => {
        if (e.type === "blur" || (e.type === "keydown" && e.key === "Enter")) {
            if (inputValue.length >= 3) {
                console.log('Saving value:', inputValue); // Debugging
                dispatch(updateTask({ id: row.id, field: 'value', value: inputValue })); // Dispatch update action
                setIsEditing(false); // Exit edit mode
            } else {
                alert("Input must be at least 3 characters long.");
            }
        }
    };

    // Function to handle row click: log row ID and toggle sidebar
    const handleRowClick = () => {
        console.log("Row ID:", row.id); // Log the row id to the console
        setIsSidebarOpen(!isSidebarOpen); // Toggle the sidebar state
        document.getElementById('openSidebar')?.click(); // Trigger the sidebar toggle if an element with id 'openSidebar' exists
    };

    return (
        <tr onClick={handleRowClick}>
            <td></td>
            <td>
                {isEditing ? (
                    <InputBox
                        value={inputValue}
                        onChange={handleInputChange}
                        onBlur={handleBlurOrEnter}
                        onKeyDown={handleBlurOrEnter} // Use only onKeyDown for handling Enter
                        autoFocus
                    />
                ) : (
                    <span onDoubleClick={handleDoubleClick}>
                        {row.value || "Double-click to edit"}
                    </span>
                )}
            </td>
            <td>
                <CircularProgressBar
                    percentage={row.status}
                    onChange={(e) => handleStatusChange(e, row.id)}
                />
            </td>
            <td>
                <MyDatePicker
                    selectedDate={row.dueDate}
                    onChange={(date) => handleDueDateChange(date, row.id)}
                />
            </td>
            <td>
                {row.organisation ? (
                    <ImageGallery images={[row.organisation]} />
                ) : (
                    <img src={OrganisationIcon} alt="Organisation" />
                )}
            </td>
            <td>
                {row.assignee ? (
                    <ImageGallery images={[row.assignee]} shape="circle" />
                ) : (
                    <img src={AssigneeIcon} alt="Assignee" />
                )}
            </td>
            <td>
                <img src={CommentsIcon} alt="Comments" />
            </td>
            <td>
                <Tags
                    tags={row.tags || []}
                    onRemoveTag={(tagIndex) => handleTagsChange(tagIndex, row.id)}
                />
            </td>
        </tr>
    );
};

export default TaskRow;
